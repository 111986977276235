import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact from "../components/contact";
import Logo from "../components/logo";

const IndexPage = () => (
    <Layout>
        <Seo title="Smaller Jobs Welcome" />
        <HeaderLogo />
        <Header />
        <TaskList />
        <AboutBlurb />
        <Contact />
    </Layout>
)


const HeaderLogo = () => (
    <header className="mastlogo bg-white text-white text-center">
        <div className="container d-flex align-items-center flex-column">
            <Logo />
        </div>
    </header>
)

const Header = () => (
    <header className="masthead bg-primary text-white text-center">
        <div className="container d-flex align-items-center flex-column">
            <div className="divider-custom divider-light">
                <h1 className="masthead-heading text-uppercase mb-0">Smaller Jobs Welcome</h1>

            </div>
            <p className="masthead-subheading font-weight-light mb-0">Free Quotes - Pensioner Discount - Prompt Service</p>

            <div className="text-center mt-4">
                <a className="btn btn-xl btn-outline-light" href="tel:0432479731">
                    Gives us a call
                </a>
            </div>
        </div>
    </header>
)

const AboutBlurb = () => (
    <section className="page-section bg-primary text-white mb-0" id="about">
        <div className="container">
            {/* <!-- About Section Heading--> */}
            <h2 className="page-section-heading text-center text-uppercase text-white">About Just Hire A Handyman</h2>
            {/* <!-- Icon Divider--> */}
            <div className="divider-custom divider-light">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                <div className="divider-custom-line"></div>
            </div>
            {/* <!-- About Section Content--> */}
            <div className="row">
                <div className="col-lg-4 ms-auto">
                    <p className="lead">
                        Leave the repairs that you need done around the house or the never ending list of jobs to Tom from Just Hire a Handyman. We offer a number of professional home and commercial maintenance services designed to help your needs, up to the value of $3300.
                    </p>
                </div>
                <div className="col-lg-4 me-auto">
                    <p className="lead">
                        Just Hire a Handyman is a Handyman and Repair service focusing on your home or commercial property repairs. Servicing Brisbane Northside area, Just Hire a Handyman strives to complete high quality work at a competitive price and in a timely manner. We are happy to look after regular maintenance as well as one off projects.
                        </p>
                </div>
            </div>
        </div>
    </section>
)

const TaskList = () => (
    <section className="page-section portfolio" id="services">
        <div className="container">
            {/* <!-- Portfolio Section Heading--> */}
            <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">How we can help</h2>
            {/* <!-- Icon Divider--> */}
            <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                <div className="divider-custom-line"></div>
            </div>
            <div className="row justify-content-center">
                {/* <!-- Portfolio Item 1--> */}
                <div className="col-md-6 col-lg-4 mb-5">
                    <ul className="list-group">
                        <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1 text-center text-box-item">Curtain & Blind Installation</h5>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1 text-center text-box-item">Flyscreen repairs</h5>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1 text-center text-box-item">Picture/Mirror Hanging</h5>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1 text-center text-box-item">Installing Shelves</h5>
                            </div>
                        </li>
                    </ul>
                </div>
                {/* <!-- Portfolio Item 2--> */}
                <div className="col-md-6 col-lg-4 mb-5">
                    <ul className="list-group">
                        <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1 text-center text-box-item">Small Painting Jobs</h5>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1 text-center text-box-item">Water Blasting</h5>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1 text-center text-box-item">Doors Locks Installed</h5>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1 text-center text-box-item">Change Lightbulbs</h5>
                            </div>
                        </li>

                    </ul>
                </div>
                {/* <!-- Portfolio Item 3--> */}
                <div className="col-md-6 col-lg-4 mb-5">
                    <ul className="list-group">
                        <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1 text-center text-box-item">Flat pack assembly</h5>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1 text-center text-box-item">Furniture Assembly</h5>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1 text-center text-box-item">Hanging Towel Rails</h5>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1 text-center text-box-item">Other Odd Jobs</h5>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </section>
)



export default IndexPage
