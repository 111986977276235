import * as React from "react"
import { navigate } from 'gatsby'
import Recaptcha from 'react-google-recaptcha'
const RECAPTCHA_KEY = "6LeRxikbAAAAAJ8tOusbrGeuxdX-V6DPkB5SuLEp"

const recaptchaRef = React.createRef() // new Ref for reCaptcha
const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = { name: "", email: "", message: "", phone: "" };
    }

    /* Here’s the juicy bit for posting the form submission */

    handleSubmit = e => {
        e.preventDefault();
       recaptchaRef.current.executeAsync().then(recaptchaVal => {
            const form = e.target
            const recaptchaValue = recaptchaRef.current.getValue()

            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({
                    "form-name": "contact-us",
                    'g-recaptcha-response': recaptchaValue,
                    ...this.state,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        navigate(form.getAttribute('action'));
                    } else {
                        throw new Error('Something went wrong');
                    }
                })
                .catch(() => alert("An Error has occured, please use the contact us button"));
        })
    }

    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    render() {
        const { name, email, phone, message } = this.state;
        return (
            <section className="page-section" id="contact">
                <div className="container">
                    {/* <!-- Contact Section Heading--> */}
                    <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Contact Us</h2>
                    {/* <!-- Icon Divider--> */}
                    <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                        <div className="divider-custom-line"></div>
                    </div>
                    {/* <!-- Contact Section Form--> */}
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xl-7">
                            <form name="contact-us"
                                method="POST"
                                data-netlify="true"              // the Netlify data attribute
                                data-netlify-recaptcha="true"
                                action="/thankyou"
                                onSubmit={this.handleSubmit}>
                                <div className="form-floating">
                                    <input className="form-control" id="inputName" type="text" placeholder="Enter your name..." name="name" value={name} onChange={this.handleChange} />
                                    <label htmlFor="inputName">Name</label>
                                </div>
                                <div className="form-floating">
                                    <input className="form-control" id="inputEmail" type="email" placeholder="Enter your email..." name="email" value={email} onChange={this.handleChange} />
                                    <label htmlFor="inputEmail">Email address</label>
                                </div>
                                <div className="form-floating">
                                    <input className="form-control" id="inputPhone" type="tel" placeholder="Enter your phone number..." name="phone" value={phone} onChange={this.handleChange} />
                                    <label htmlFor="inputPhone">Phone Number</label>
                                </div>
                                <div className="form-floating">
                                    <textarea className="form-control" id="inputMessage" placeholder="Enter your message here..." style={{ height: "12rem" }} name="message" value={message} onChange={this.handleChange}></textarea>
                                    <label htmlFor="inputMessage">Message</label>
                                </div>
                                <br />
                                <Recaptcha
                                    ref={recaptchaRef}
                                    sitekey={RECAPTCHA_KEY}
                                    size="invisible"
                                    id="recaptcha-google"
                                />
                                <button className="btn btn-primary btn-xl" type="submit">Send</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}


export default Contact;